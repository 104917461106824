import { default as _91_46_46_46cms_93CnH5UWy2uhMeta } from "/var/www/astondom-frontend/all/astondom-front-20250214080424/pages/[...cms].vue?macro=true";
import { default as _91_46_46_46slug_93FRxGHK2igtMeta } from "/var/www/astondom-frontend/all/astondom-front-20250214080424/pages/blog/[...slug].vue?macro=true";
import { default as indexfZB8ZcLkxWMeta } from "/var/www/astondom-frontend/all/astondom-front-20250214080424/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93isAwjT1RRqMeta } from "/var/www/astondom-frontend/all/astondom-front-20250214080424/pages/catalog/[...slug].vue?macro=true";
import { default as temp_catalog_need_32_removeDaWYINVE4QMeta } from "/var/www/astondom-frontend/all/astondom-front-20250214080424/pages/catalog/temp_catalog_need _remove.vue?macro=true";
import { default as _91_46_46_46slug_93AylKauC1rAMeta } from "/var/www/astondom-frontend/all/astondom-front-20250214080424/pages/product/[...slug].vue?macro=true";
import { default as index3ISMRwWYOwMeta } from "/var/www/astondom-frontend/all/astondom-front-20250214080424/pages/product/index.vue?macro=true";
import { default as _91_46_46_46slug_93SFWlbM8HS0Meta } from "/var/www/astondom-frontend/all/astondom-front-20250214080424/pages/realty/[...slug].vue?macro=true";
import { default as indexOxOYDWUNE7Meta } from "/var/www/astondom-frontend/all/astondom-front-20250214080424/pages/sitemap/index.vue?macro=true";
import { default as indexX673iucbUOMeta } from "/var/www/astondom-frontend/all/astondom-front-20250214080424/pages/wish/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46cms_93CnH5UWy2uhMeta?.name ?? "cms",
    path: _91_46_46_46cms_93CnH5UWy2uhMeta?.path ?? "/:cms(.*)*",
    meta: _91_46_46_46cms_93CnH5UWy2uhMeta || {},
    alias: _91_46_46_46cms_93CnH5UWy2uhMeta?.alias || [],
    redirect: _91_46_46_46cms_93CnH5UWy2uhMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20250214080424/pages/[...cms].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93FRxGHK2igtMeta?.name ?? "blog-slug",
    path: _91_46_46_46slug_93FRxGHK2igtMeta?.path ?? "/blog/:slug(.*)*",
    meta: _91_46_46_46slug_93FRxGHK2igtMeta || {},
    alias: _91_46_46_46slug_93FRxGHK2igtMeta?.alias || [],
    redirect: _91_46_46_46slug_93FRxGHK2igtMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20250214080424/pages/blog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexfZB8ZcLkxWMeta?.name ?? "blog",
    path: indexfZB8ZcLkxWMeta?.path ?? "/blog",
    meta: indexfZB8ZcLkxWMeta || {},
    alias: indexfZB8ZcLkxWMeta?.alias || [],
    redirect: indexfZB8ZcLkxWMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20250214080424/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93isAwjT1RRqMeta?.name ?? "catalog-slug",
    path: _91_46_46_46slug_93isAwjT1RRqMeta?.path ?? "/catalog/:slug(.*)*",
    meta: _91_46_46_46slug_93isAwjT1RRqMeta || {},
    alias: _91_46_46_46slug_93isAwjT1RRqMeta?.alias || [],
    redirect: _91_46_46_46slug_93isAwjT1RRqMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20250214080424/pages/catalog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: temp_catalog_need_32_removeDaWYINVE4QMeta?.name ?? "catalog-temp_catalog_need _remove",
    path: temp_catalog_need_32_removeDaWYINVE4QMeta?.path ?? "/catalog/temp_catalog_need%20_remove",
    meta: temp_catalog_need_32_removeDaWYINVE4QMeta || {},
    alias: temp_catalog_need_32_removeDaWYINVE4QMeta?.alias || [],
    redirect: temp_catalog_need_32_removeDaWYINVE4QMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20250214080424/pages/catalog/temp_catalog_need _remove.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93AylKauC1rAMeta?.name ?? "product-slug",
    path: _91_46_46_46slug_93AylKauC1rAMeta?.path ?? "/product/:slug(.*)*",
    meta: _91_46_46_46slug_93AylKauC1rAMeta || {},
    alias: _91_46_46_46slug_93AylKauC1rAMeta?.alias || [],
    redirect: _91_46_46_46slug_93AylKauC1rAMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20250214080424/pages/product/[...slug].vue").then(m => m.default || m)
  },
  {
    name: index3ISMRwWYOwMeta?.name ?? "product",
    path: index3ISMRwWYOwMeta?.path ?? "/product",
    meta: index3ISMRwWYOwMeta || {},
    alias: index3ISMRwWYOwMeta?.alias || [],
    redirect: index3ISMRwWYOwMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20250214080424/pages/product/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93SFWlbM8HS0Meta?.name ?? "realty-slug",
    path: _91_46_46_46slug_93SFWlbM8HS0Meta?.path ?? "/realty/:slug(.*)*",
    meta: _91_46_46_46slug_93SFWlbM8HS0Meta || {},
    alias: _91_46_46_46slug_93SFWlbM8HS0Meta?.alias || [],
    redirect: _91_46_46_46slug_93SFWlbM8HS0Meta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20250214080424/pages/realty/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexOxOYDWUNE7Meta?.name ?? "sitemap",
    path: indexOxOYDWUNE7Meta?.path ?? "/sitemap",
    meta: indexOxOYDWUNE7Meta || {},
    alias: indexOxOYDWUNE7Meta?.alias || [],
    redirect: indexOxOYDWUNE7Meta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20250214080424/pages/sitemap/index.vue").then(m => m.default || m)
  },
  {
    name: indexX673iucbUOMeta?.name ?? "wish",
    path: indexX673iucbUOMeta?.path ?? "/wish",
    meta: indexX673iucbUOMeta || {},
    alias: indexX673iucbUOMeta?.alias || [],
    redirect: indexX673iucbUOMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20250214080424/pages/wish/index.vue").then(m => m.default || m)
  }
]