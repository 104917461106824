import {errorParserServerResponse, useNuxtApp} from '#imports';
import type { Ref } from '#imports';
import { redirectToIp } from '~/consts/citySelectionRedirects';

export type CityListCityType = {
    id: number
    name: string
    region_id: number
    has_data: boolean
    data_id: number
    sub_domain: string
}

type CityListRegion = {
    cms_data?: string;
    id: number;
    name: string;
}

type CityListType = {
    favorites: CityListCityType[];
    cities: CityListCityType[];
    regions: CityListRegion[];
}

type UseUserCityType = {
    citiesList: Ref<CityListType | null | undefined>
    getAllCitiesList: () => Promise<CityListType | null | undefined>;
    setCityUser: (cityId: number) => Promise<void>;
    checkCurrentCity:()=>Promise<void>;
}

export function useUserCity (): UseUserCityType {
  const { $api, $toast } = useNuxtApp();
  const { baseDomain } = useRuntimeConfig().public;

  const citiesList = useState<CityListType | null | undefined>('citiesAllList', () => null);

  const checkCurrentCity = async () => {
    const defaultCity = `https://ekb.${baseDomain}.ru`
    const currentCity = await $api.agent.get('/cities/current-by-ip')
      .then((res) => {
        return res._data
      })
      .catch((err) => {
        console.log('check-current-city error:', errorParserServerResponse(err))

        return null
      });

    if (currentCity) {
      console.log('current city', currentCity)
      window.location.href = redirectToIp()[currentCity.region_id] || defaultCity;
    }
  };

  const getAllCitiesList = async (): Promise<CityListType | null | undefined> => {
    const res = await $api.agent.get<CityListType | null>('cities')
      .then((res) => res._data)
      .catch(() => {
        console.log('error while response all cities');
        return null;
      });

    citiesList.value = res;

    return res;
  };

  const setCityUser = async (cityId: number) => {
    console.log(cityId);
    const response = await $api.agent.put('/cities/current', {
      body: {
        city_id: cityId
      }
    }).then((res) => ({
      ...res._data,
      'x-redirect': res?.headers?.get('x-redirect')
    })).catch(() => ({ error: true }));

    if (response.error) {
      $toast.error('Ошибка установки города, повторите попытку позднее.');
      return;
    }
    console.log(response);
    window.location.href = response?.['x-redirect'];
  };

  return {
    citiesList,

    checkCurrentCity,
    setCityUser,
    getAllCitiesList
  };
}
